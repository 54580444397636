<template>
  <mly-pc-wrap path="./"></mly-pc-wrap>
</template>

<script>
export default {};
</script>

<style>
</style>
